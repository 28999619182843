import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class CategoryProductService {
    getAll() {
        return http.get("/Category",httpOptions);
    }
    getTrash() {
        return http.get("/Category/TrashCategory",httpOptions);
    }
    get(id) {
        return http.get(`/Category/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/Category", data,httpOptions);
    }
    update(data) { 
        return http.post(`/Category/PutCategory`, data,httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/Category/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/Category/RepeatCategory`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/Category/${id}`,httpOptions);
        }
}
export default new CategoryProductService();