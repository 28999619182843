import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class ContactService {
    getAll() {
        return http.get("/Contact",httpOptions);
    }
    get(id) {
        return http.get(`/Contact/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/Contact", data,httpOptions);
    }
    update(data) {
        return http.post(`/Contact/PutContact`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/Contact/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/Contact/TrashContact",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/Contact/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/Contact/RepeatContact`, data,httpOptions);
    }
}
export default new ContactService();