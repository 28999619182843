import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class VoucherService {
    getAll() {
        return http.get("/Voucher",httpOptions);
    }
    get(id) {
        return http.get(`/Voucher/${id}`,httpOptions);
    }
    getVoucherPro(id) {
        return http.get(`/Voucher/GetVoucherPro?id=${id}`,httpOptions);
    }
    getVoucherProActive(id) {
        return http.get(`/Voucher/GetVoucherProActive?id=${id}`,httpOptions);
    }
    updatedate(data) {
        return http.post(`/Voucher/VoucherHangfire`, data,httpOptions);
    }
    cancelupdatedate(data) {
        return http.post(`/Voucher/VoucherHangfireCancel`, data,httpOptions);
    }
    create(data) {
        return http.post("/Voucher", data,httpOptions);
    }
    update(data) {
        return http.post(`/Voucher/PutVoucher`, data,httpOptions);
    }
    delete(id) {
        return http.delete(`/Voucher/${id}`,httpOptions);
    }
}
export default new VoucherService();