import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class AccountUserService {
    getAll() {
        return http.get("/AccountUser",httpOptions);
    }
    get(id) {
        return http.get(`/AccountUser/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/AccountUser", data,httpOptions);
    }
    update(data) {
        return http.post("/AccountUser/PutAccountUser", data,httpOptions);
    }
    delete(id) {
        return http.delete(`/AccountUser/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/AccountUser/TrashAccountUsers",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/AccountUser/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/AccountUser/RepeatAccountUsers`, data,httpOptions);
    }
    getOrderDebit() {
        return http.get("/AccountUser/GetOrderDebit",httpOptions);
    }
    

}
export default new AccountUserService();