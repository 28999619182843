import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class OrderDetailServiceCus {
    getAll() {
        return http.get("/OrderDetailCus",httpOptions);
    }
    get(id) {
        return http.get(`/OrderDetailCus/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/OrderDetailCus", data,httpOptions);
    }
    update(data) {
        return http.post(`/OrderDetailCus/${data.id}`, data,httpOptions);
    }
    delete(id) {
        return http.delete(`/OrderDetailCus/${id}`,httpOptions);
    }
}
export default new OrderDetailServiceCus();