<template>
<div class="card-header">
    <h5>Quản lý Payment</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow">

                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                           <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">TransactionId</th>
                                        <th class="text-center">Mã giao dịch</th>
                                        <th class="text-center">Tổng tiền thanh toán</th>
                                        <th class="text-center">Thông tin khách hàng thanh toán</th>
                                        <th class="text-center">Ngày đặt hàng</th>
                                        <th class="text-center">Trạng thái</th>
                                    </tr>
                                </thead>
                                <tbody v-if="query">
                                    <tr v-for="item in paymentFilte" :key="item">
                                        <td class="text-center">{{ item.transactionId }}</td>
                                        <td class="text-center">{{ item.paymentCode }}</td>
                                        <td class="text-center">{{ formatPrice(item.amount) }} VNĐ</td>
                                        <td class="text-center">{{ item.paymentInfor }}</td>
                                        <td class="text-center">{{ format_date(item.createdDate)}}</td>
                                        
                                        <td class="text-center">
                                            <span class="badge badge-success" v-if="item.status == '00'">Đã thanh toán</span>
                                            <span class="badge badge-danger" v-if="item.status == '24'">Đã hủy giao dịch</span>
                                            <span class="badge badge-danger" v-if="item.status != '24' && item.status != '00'">Giao dịch không thành công</span>
                                        </td>
                                        
                                    </tr>
                                </tbody>

                                <tbody v-else>
                                    <tr v-for="item in paginated" :key="item">
                                        <td class="text-center">{{ item.transactionId }}</td>
                                        <td class="text-center">{{ item.paymentCode }}</td>
                                        <td class="text-center">{{ formatPrice(item.amount) }} VNĐ</td>
                                        <td class="text-center">{{ item.paymentInfor }}</td>
                                        <td class="text-center">{{ format_date(item.createdDate)}}</td>
                                        
                                        <td class="text-center">
                                            <span class="badge badge-success" v-if="item.status == '00'">Đã thanh toán</span>
                                            <span class="badge badge-danger" v-if="item.status == '24'">Đã hủy giao dịch</span>
                                            <span class="badge badge-danger" v-if="item.status != '24' && item.status != '00'">Giao dịch không thành công</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="column is-12">
                            <div class="example-six">
                                <vue-awesome-paginate :total-items="resultCount" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                                    <template #prev-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>

                                    <template #next-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>
                                </vue-awesome-paginate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.example-six .pagination-container {
    column-gap: 10px;
    align-items: center;
}

.example-six .paginate-buttons {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: black;
    border: 1px solid;
}

.example-six .back-button,
.example-six .next-button {
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.example-six .active-page {
    background-color: #20c997;
    color: white;
}

.example-six .paginate-buttons:hover {
    background-color: #f5f5f5;
}

.example-six .active-page:hover {
    background-color: #20c997;
}

.example-six .back-button svg {
    transform: rotate(180deg) translateY(-2px);
}

.example-six .next-button svg {
    transform: translateY(2px);
}

.example-six .back-button:hover,
.example-six .next-button:hover {
    background-color: rgb(45, 45, 45);
}

.example-six .back-button:active,
.example-six .next-button:active {
    background-color: rgb(85, 85, 85);
}
</style>

<script>
import PaymentService from "../../services/PaymentService";
import moment from 'moment';
import {
    ref
} from "vue";
import "vue-awesome-paginate/dist/style.css";
export default {
    name: "Index",
    data() {
        return {
            payment: null,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false
        };
    },

    mounted() {
        PaymentService.getAll()
            .then((res) => {
                this.payment = res.data;
                console.log(this.payment);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            });
    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        
        resultCount() {
            return this.payment && this.payment.length
        },
        paymentFilte() {
            if (this.query) {
                return this.payment.filter((payment) => {
                    return (
                        payment.paymentInfor
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.payment;
            }

        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.payment.slice(this.indexStart, this.indexEnd);
            } else {
                return this.payment;
            }
        }
    },

    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        getTokenConfig() {
            let token = JSON.parse(sessionStorage.getItem("token"));
            const config = {
                headers: {
                    Authorization: 'Bearer' + token
                }
            }
            return config;
        },
        onClickHandler(page) {
            this.current = page.current;
        },
    }
};
</script>
