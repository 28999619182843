import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class OrderServicesCus {
    getAll() {
        return http.get("/OrderCus",httpOptions);
    }
    getAllSuccess() {
        return http.get("/OrderCus/orderSuccess",httpOptions);
    }
    get(id) {
        return http.get(`/OrderCus/${id}`,httpOptions);
    }
    getAllReturns() {
        return http.get("/OrderCus/orderReturn",httpOptions);
    }
    create(data) {
        return http.post("/OrderCus", data,httpOptions);
    }
    update(data) {
        return http.post("/OrderCus/PutOrderCus", data,httpOptions);
    }
    delete(id) {
        return http.delete(`/OrderCus/${id}`,httpOptions);
    }
    updateQuantity(data) {
        return http.post("/OrderCus/ProductQuantity", data,httpOptions)
    }
}
export default new OrderServicesCus();