import { createRouter, createWebHistory } from "vue-router";
import AccountAdmin from "./view/AccountAdmin/index";
import Category from "./view/Category/index";
import CategoryNews from "./view/CategoryNews/index"
import CategoryPolicy from "./view/CategoryPolicy/index"
import PolicyList from "./view/PolicyDetail/index"
import NewsList from "./view/NewDetail/index"
import UserLanding from "./view/UserLanding/index"
import Banner from "./view/Banner/index";
import AccountUser from "./view/AccountUser/index";
import Product from "./view/Product/index";
import ProductAdd from "./view/Product/add";
import Login from "./view/login";
import Home from "./view/home";
import Feedback from "./view/Feedback/index";
import ImageProduct from "./view/ImageProduct/index";
import Application from "./view/Application/index";
import Characteristic from "./view/Characteristic/index";
import CharacterList from "./view/CharacterList/index";
import Service from "./view/Service/index";
import Question from "./view/Question/index";
import QuestionService from "./view/QuestionService/index";
import ServiceDetail from "./view/ServiceDetail/index";
import Contact from "./view/Contact/index";
import Welcome from "./view/Welcome/index";
import WelcomeDetail from "./view/WelcomeDetail/index";
import Recrutement from "./view/Recrutement/index";
import HomeSeo from "./view/HomeSeo/index";
import ContactSeo from "./view/ContactSeo/index";
import ProductSeo from "./view/ProductSeo/index";
import NewSeo from "./view/NewSeo/index";
import RecrutementSeo from "./view/RecrutementSeo/index";
import WarrantySeo from "./view/WarrantySeo/index";
import Warranty from "./view/Warranty/index";
import City from "./view/City/index";
import Attribute from "./view/Attributes/index";
import ValueAttribute from "./view/ValueAttribute/index";
import Item from "./view/Item/index";
import Country from "./view/Country/index";
import Location from "./view/Location/index";
import Gift from "./view/Gift/index";
import Order from "./view/Order/index";
import OrderSpectraVn from "./view/OrderSpectraBrand/index";
import OrderCustomer from "./view/OrderCustomer/index";
import AgencySeo from "./view/AgencySeo/index";
import Payment from "./view/Payment/index";
import SeriProduct from "./view/SeriProduct/index";
import OrderDetail from "./view/OrderDetail/index";
import Voucher from "./view/Voucher/index";
import Brand from "./view/Brand/index";
const routes = [{
        path: "/",
        name: "Home",
        component: Home,

    },
    {
        path: "/homeseo",
        name: "homeseo",
        component: HomeSeo,
    },
    {
        path: "/contactseo",
        name: "contactseo",
        component: ContactSeo,
    },
    {
        path: "/newseo",
        name: "newseo",
        component: NewSeo,
    },
    {
        path: "/productseo",
        name: "productseo",
        component: ProductSeo,
    },
    {
        path: "/brand",
        name: "brand",
        component: Brand,
    },
    {
        path: "/recruitmentseo",
        name: "recruitmentseo",
        component: RecrutementSeo,
    },
    {
        path: "/agencyseo",
        name: "agencyseo",
        component: AgencySeo,
    },
    {
        path: "/warrantyseo",
        name: "warrantyseo",
        component: WarrantySeo,
    },
    {
        path: "/warranty",
        name: "warranty",
        component: Warranty,
    },
    {
        path: "/city",
        name: "city",
        component: City,
    },
    {
        path: "/country",
        name: "country",
        component: Country,
    },
    {
        path: "/attribute",
        name: "attribute",
        component: Attribute,
    },
    {
        path: "/location",
        name: "location",
        component: Location,
    },
    {
        path: "/valueattribute",
        name: "valueattribute",
        component: ValueAttribute,
    },
    {
        path: "/item",
        name: "item",
        component: Item,
    },
    {
        path: "/account-manager",
        name: "account",
        component: AccountAdmin,
    },
    {
        path: "/order",
        name: "order",
        component: Order,
    },
    {
        path: "/orderspectravn",
        name: "orderspectravn",
        component: OrderSpectraVn,
    },
    {
        path: "/payment",
        name: "payment",
        component: Payment,
    },
    {
        path: "/proadd",
        name: "proadd",
        component: ProductAdd,
    },
    {
        path: "/categoryProduct",
        name: "category",
        component: Category,
    },
    {
        path: "/imageProduct",
        name: "imageProduct",
        component: ImageProduct,
    },
    {
        path: "/gift",
        name: "gift",
        component: Gift,
    },
    {
        path: "/application",
        name: "application",
        component: Application,
    },
    {
        path: "/characteristic",
        name: "characteristic",
        component: Characteristic,
    },
    {
        path: "/characterList",
        name: "characterList",
        component: CharacterList,
    },
    {
        path: "/categoryNews",
        name: "categoryNews",
        component: CategoryNews,
    },
    {
        path: "/service",
        name: "service",
        component: Service,
    },
    {
        path: "/questionservice",
        name: "questionservice",
        component: QuestionService,
    },
    {
        path: "/userLanding",
        name: "userLanding",
        component: UserLanding,
    },
    {
        path: "/policy",
        name: "policy",
        component: CategoryPolicy,
    },
    {
        path: "/newslist",
        name: "newslist",
        component: NewsList,
    },
    {
        path: "/policylist",
        name: "policylist",
        component: PolicyList,
    },
    {
        path: "/policylist",
        name: "policylist",
        component: PolicyList,
    },
    {
        path: "/banner",
        name: "banner",
        component: Banner,
    },
    {
        path: "/question",
        name: "question",
        component: Question,
    },
    {
        path: "/servicedetail",
        name: "servicedetail",
        component: ServiceDetail,
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
    },
    {
        path: "/account_user",
        name: "account_user",
        component: AccountUser,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/product-manager",
        name: "product",
        component: Product,
    },
    {
        path: "/feedback",
        name: "feedback",
        component: Feedback,
    }
    ,
    {
        path: "/welcome",
        name: "welcome",
        component: Welcome,
    }
    ,
    {
        path: "/welcomedetail",
        name: "welcomedetail",
        component: WelcomeDetail,
    }
    ,
    {
        path: "/recrutement",
        name: "recrutement",
        component: Recrutement,
    },
    {
        path: "/order-customer",
        name: "ordercustomer",
        component: OrderCustomer,
    },
    {
        path: "/seriproduct",
        name: "seriproduct",
        component: SeriProduct,
    },
    {
        path: "/orderdetail",
        name: "orderdetail",
        component: OrderDetail,
    },
    {
        path: "/voucher",
        name: "voucher",
        component: Voucher,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem("user");

    if (authRequired && !loggedIn) {
        return next({
            path: "/login",
            query: { returnUrl: to.path }
        });
    }
    next();
});
// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next()
//             return
//         }
//         next('/')
//     } else {
//         next()
//     }
// })
export default router;