<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa Voucher</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="vouchers">
                <input type="hidden" v-model="vouchers.id">

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã Voucher</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="voucherCode" placeholder="" v-model="vouchers.voucherCode" :class="{ error: voucherCodeError.status, success: voucherCodeSuccess.status }">
                                <p class="text-error" v-if="voucherCodeError.status">{{ voucherCodeError.text }}</p>
                                <p class="success-text" v-if="voucherCodeSuccess.status">{{ voucherCodeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="discountType" class="col-xl-12 col-sm-12 col-form-label">Loại giảm giá</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control" id="discountType" v-model="vouchers.discountType">
                                    <option value="amount">Số tiền</option>
                                    <option value="percentage">Phần trăm</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Discount</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="number" class="form-control" id="discount" min="1" placeholder="" v-model="vouchers.discount" :class="{ error: discountError.status, success: discountSuccess.status }">
                                <p class="text-error" v-if="discountError.status">{{ discountError.text }}</p>
                                <p class="success-text" v-if="discountSuccess.status">{{ discountSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="vouchers.productId" :class="{ error: productIdError.status, success: productIdSuccess.status }">
                                    <option value="">Chọn</option>
                                    <option v-for="item in product" :key="item.id" :selected="vouchers.productId === item.id" v-bind:value="item.id">{{
                                                            item.name
                                                        }}</option>
                                </select>
                                <p class="text-error" v-if="productIdError.status">{{ productIdError.text }}</p>
                                <p class="success-text" v-if="productIdSuccess.status">{{ productIdSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="col-lg-12">
                            <div class="row" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding: 20px 0px; margin-bottom: 25px;">
                                <div class="col-lg-12">
                                    <p class="ml-2" style="color: red; font-weight: 600; font-size: 18px;">Đặt lịch voucher sản phẩm</p>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày bắt đầu:</label>
                                        <div class="col-xl-12 col-lg-12 col-sm-12">
                                            <input type="datetime-local" class="form-control" id="start" placeholder="" v-model="vouchers.startDate" />
                                        </div>
                                        <p class="text-error" v-if="startDateError.status">{{ startDateError.text }}</p>
                                        <p class="success-text" v-if="startDateSuccess.status">{{ startDateSuccess.text }}</p>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày kết thúc:</label>
                                        <div class="col-xl-12 col-lg-12 col-sm-12">
                                            <input type="datetime-local" class="form-control" id="ends" placeholder="" v-model="vouchers.endDate" />
                                        </div>
                                        <p class="text-error" v-if="endDateError.status">{{ endDateError.text }}</p>
                                        <p class="success-text" v-if="endDateSuccess.status">{{ endDateSuccess.text }}</p>
                                    </div>
                                </div>
                                <div class="row col-lg-12">
                                    <div class="row col-12 form-group">
                                        <div class="col-6">
                                            <button type="button" :disabled="vouchers.status == true" class="btn btn-primary mt-3" @click="onVoucher()">Đặt lịch</button>
                                        </div>
                                        <div class="col-6">
                                            <button type="button" class="btn btn-danger mt-3" v-if="vouchers.status == true && vouchers.jobId != '0'" @click="onCancelUpdateVoucher()">Hủy áp dụng</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import VoucherService from '@/services/VoucherService';
import ProductService from '@/services/ProductService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['voucher'],

    data() {

        return {
            vouchers: this.voucher,
            url: null,
            productName: null,
            ishowImage: false,
            message: "",
            product: null,
            productIdError: {
                text: "",
                status: false,
            },
            productIdSuccess: {
                text: "",
                status: false,
            },
            discountError: {
                text: "",
                status: false,
            },
            discountSuccess: {
                text: "",
                status: false,
            },
            voucherCodeError: {
                text: "",
                status: false,
            },
            voucherCodeSuccess: {
                text: "",
                status: false,
            },
            startDateError: {
                text: "",
                status: false,
            },
            startDateSuccess: {
                text: "",
                status: false,
            },
            endDateError: {
                text: "",
                status: false,
            },
            endDateSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ProductService.getAll()
            .then((res) => {
                this.product = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        onSubmitEditForm() {
            if (this.vouchers.productId.length == 0) {
                this.productIdError = {
                    text: "productId cannot be empty!",
                    status: true
                }
                this.productIdSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.vouchers.productId.length > 0) {
                this.productIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.productIdSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.productIdSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    VoucherService.update(this.vouchers)
                        .then((res) => {
                            console.log(res);
                            this.vouchers.productName = res.data.productName;
                            this.$emit("ShowEditData", this.vouchers);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.vouchers);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onVoucher() {
            if ((this.vouchers.startDate != null && this.vouchers.endDate != null) || (this.vouchers.startDate != '0001-01-01 00:00:00.0000000' && this.vouchers.endDate != '0001-01-01 00:00:00.0000000')) {
                var date = new Date();
                var datestart = new Date(this.vouchers.startDate);
                var dateend = new Date(this.vouchers.endDate);
                if (datestart < date) {
                    createToast({
                        title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else if (dateend < datestart) {
                    createToast({
                        title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.vouchers.scheduleStatus = true;
                    VoucherService.updatedate(this.vouchers)
                        .then((res) => {
                            this.vouchers = res.data;
                            this.$emit("ShowEditData", this.vouchers);
                            createToast({
                                title: 'Đã đặt lịch voucher',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            createToast({
                                title: 'Thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {

                        });
                    
                    VoucherService.update(this.vouchers)
                        .then((res) => {
                            console.log(res);
                            this.vouchers.productName = res.data.productName;
                            this.$emit("ShowEditData", this.vouchers);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });

                    this.$emit("ShowEditData", this.vouchers);
                }

            } else {
                createToast({
                    title: 'Hãy chọn ngày tháng',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onCancelUpdateVoucher() {
            VoucherService.cancelupdatedate(this.vouchers)
                .then((res) => {
                    this.vouchers = res.data;
                    this.$emit("ShowEditData", this.vouchers);
                    createToast({
                        title: 'Bạn đã hủy đặt lịch',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                    setTimeout(() => {
                        location.reload();
                    }, 1500)
                })
                .catch((error) => {
                    console.log(error);
                    createToast({
                        title: 'Thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                })
                .finally(() => {

                });
        },
    }

}
</script>
