import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class WelcomeDetailService {
    
    getAll() {
        return http.get("/WelcomeDetail",httpOptions);
    }
    getPageWel() {
        return http.get("/PageResult/WelcomeDetail",httpOptions);
    }
    getOnChangePageWel(pageNumber) {
        return http.get(`/PageResult/WelcomeDetail?page=${pageNumber}`,httpOptions);
    }
    get(id) {
        return http.get(`/WelcomeDetail/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/WelcomeDetail", data,httpOptions);
    }
    update(data) {
        return http.post(`/WelcomeDetail/PutWelcomeDetail`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/WelcomeDetail/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new WelcomeDetailService();