import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class OrderService {
    getAll() {
        return http.get("/Order",httpOptions);
    }
    getPageOrder() {
        return http.get("/Order/OrderPage",httpOptions);
    }
    sendmailcancel(data){
        return http.post("/orderdetail/SendEmailCancel", data,httpOptions);
    }
    getOnChangePageOrder(pageNumber) {
        return http.get(`/Order/OrderPage?page=${pageNumber}`,httpOptions);
    }
    getPageOrderBrand() {
        return http.get("/Order/OrderPageBrand",httpOptions);
    }
    getOnChangePageOrderBrand(pageNumber) {
        return http.get(`/Order/OrderPageBrand?page=${pageNumber}`,httpOptions);
    }
    getAllSuccess() {
        return http.get("/Order/orderSuccess",httpOptions);
    }
    get(id) {
        return http.get(`/Order/${id}`,httpOptions);
    }
    getAllReturns() {
        return http.get("/Order/orderReturn",httpOptions);
    }
    create(data) {
        return http.post("/Order", data,httpOptions);
    }
    update(data) {
        return http.post("/Order/PutOrder", data,httpOptions);
    }
    delete(id) {
        return http.delete(`/Order/${id}`,httpOptions);
    }
    updateQuantity(data) {
        return http.post("/Order/ProductQuantity", data,httpOptions)
    }
}
export default new OrderService();