<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới Contact Seo</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">TitleSeo :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="contactseo.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }" />
                                <p class="text-error" v-if="titleSeoError.status">
                                    {{ titleSeoError.text }}
                                </p>
                                <p class="success-text" v-if="titleSeoSuccess.status">
                                    {{ titleSeoSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">KeyWords :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="contactseo.metaKeyWords" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="success-text" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <textarea name="" class="form-control" id="" cols="30" v-model="contactseo.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }" rows="10"></textarea>
                                <p class="text-error" v-if="metaDescriptionError.status">
                                    {{ metaDescriptionError.text }}
                                </p>
                                <p class="success-text" v-if="metaDescriptionSuccess.status">
                                    {{ metaDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ContactSeoService from "../../services/ContactSeoService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["contactseos"],
    name: "add-contactseo",
    data() {
        return {
            getContactseo: this.contactseos,
            message: "",
            currentImage: undefined,
            url: null,
            ID: null,
            a: null,
            contactseo: {
                id: null,
                titleSeo: "",
                metaKeyWords: "",
                metaDescription: "",
            },
            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
        };
    },

    methods: {
        onSubmitForm() {
            if (this.contactseo.titleSeo.length == 0) {
                this.titleSeoError = {
                    text: "Title cannot be empty!",
                    status: true
                };
                this.titleSeoSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.contactseo.titleSeo.length > 0) {
                this.titleSeoSuccess = {
                    text: "Success!",
                    status: true
                }
                this.titleSeoError = {
                    text: "",
                    status: false
                }
            } else {
                this.titleSeoSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.contactseo.metaKeyWords.length == 0) {
                this.nameError = {
                    text: "KeyWords cannot be empty!",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.contactseo.metaKeyWords.length > 0) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.contactseo.metaDescription.length == 0) {
                this.metaDescriptionError = {
                    text: "metaDescription cannot be empty!",
                    status: true
                };
                this.metaDescriptionSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.contactseo.metaDescription.length > 0) {
                this.metaDescriptionSuccess = {
                    text: "Success!",
                    status: true
                }
                this.metaDescriptionError = {
                    text: "",
                    status: false
                }
            } else {
                this.metaDescriptionSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.titleSeoSuccess.status == true && this.nameSuccess.status == true && this.metaDescriptionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {

                    ContactSeoService.create(this.contactseo)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.contactseo.id = this.ID;
                            console.log(res.data);
                            this.$emit("ShowData", this.contactseo);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Thêm mới thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },

    },
};
</script>
