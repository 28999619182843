<template>
<div class="card-header">
    <h5>Quản lý seri Sản phẩm</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">

        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">

                <div class="statbox widget box box-shadow" v-if="isShowEdit == false && isShowAdd == false && isShowTrash == false">
                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <a v-on:click.prevent="onAdd" class="btn btn-success mr-3">Thêm Mới +</a>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm theo mã seri, tên đại lý, sản phẩm">
                            </div>
                        </div>
                        <div class="row col-md-8">
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <label class="col-md-3" for="">Từ ngày</label>
                                    <input type="date" class="col-md-9 form-control" v-model="startDate">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row form-group">
                                    <label class="col-md-3" for="">Đến ngày</label>
                                    <input type="date" class="col-md-9 form-control" v-model="endDate">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">Seri Sản Phẩm</th>
                                        <th class="text-center">Sản phẩm</th>
                                        <th class="text-center">Đại lý</th>
                                        <th class="text-center">Ngày Xuất kho đại lý</th>
                                        <th class="text-center">Trạng thái</th>
                                        <th class="text-center">Chức năng</th>
                                    </tr>
                                </thead>

                                <!-- <tbody v-if="query">
                                    <tr v-for="item in seriproductFilte" :key="item.id">
                                        <td class="text-center">{{ item.productSeri }}</td>
                                        <td>{{ item.productName }}</td>
                                        <td>
                                            <p>Mã ĐL: {{ item.locationCode }}</p>
                                            <p>Tên ĐL: {{ item.locationName }}</p>
                                        </td>
                                        <td>{{ format_date(item.dealerSaleDate) }}</td>
                                        <td>
                                            <p class="text-success" v-if="item.status">
                                                <span>Đã bảo hành</span>
                                            </p>
                                            <p class="text-danger" v-if="!item.status">
                                                <span>Chờ bảo hành</span>
                                            </p>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody> -->
                                <!-- <tbody v-else-if="startDate">
                                    <tr v-for="(item) in filterItem" :key="item.id">
                                        <td class="text-center">{{ item.productSeri }}</td>
                                        <td>{{ item.productName }}</td>
                                        <td>
                                            <p>Mã ĐL: {{ item.locationCode }}</p>
                                            <p>Tên ĐL: {{ item.locationName }}</p>
                                        </td>
                                        <td>{{ format_date(item.dealerSaleDate) }}</td>
                                        <td>
                                            <p class="text-success" v-if="item.status">
                                                <span>Đã bảo hành</span>
                                            </p>
                                            <p class="text-danger" v-if="!item.status">
                                                <span>Chờ bảo hành</span>
                                            </p>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody> -->
                                <tbody>
                                    <tr v-for="item in paginated" :key="item.id">
                                        <td class="text-center">{{ item.productSeri }}</td>
                                        <td>{{ item.productName }}</td>
                                        <td>
                                            <p>Mã ĐL: {{ item.locationCode }}</p>
                                            <p>Tên ĐL: {{ item.locationName }}</p>
                                        </td>
                                        <td>{{ format_date(item.dealerSaleDate) }}</td>
                                        <td>
                                            <p class="text-success" v-if="item.status">
                                                <span>Đã bảo hành</span>
                                            </p>
                                            <p class="text-danger" v-if="!item.status">
                                                <span>Chờ bảo hành</span>
                                            </p>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="column is-12">
                            <div class="example-six">
                                <vue-awesome-paginate :total-items="Count" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                                    <template #prev-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>

                                    <template #next-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>
                                </vue-awesome-paginate>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="" v-if="isShowEdit == true || isShowAdd == true || isShowTrash == true" v-on:click.prevent="back_to"><svg xmlns="http://www.w3.org/2000/svg" width="16" style="width: 32px;
                                height: 32px;" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg></a>
                <SeriProductEdit :seriproduct="showEdit" v-if="isShowEdit == true" @ShowEditData="getEdit($event)" />
                <SeriProductAdd v-if="isShowAdd == true" @ShowData="getData($event)" />
            </div>
        </div>
    </div>
</div>
</template>

<style>
.example-six .pagination-container {
    column-gap: 10px;
    align-items: center;
}

.example-six .paginate-buttons {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: black;
    border: 1px solid;
}

.example-six .back-button,
.example-six .next-button {
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.example-six .active-page {
    background-color: #20c997;
    color: white;
}

.example-six .paginate-buttons:hover {
    background-color: #f5f5f5;
}

.example-six .active-page:hover {
    background-color: #20c997;
}

.example-six .back-button svg {
    transform: rotate(180deg) translateY(-2px);
}

.example-six .next-button svg {
    transform: translateY(2px);
}

.example-six .back-button:hover,
.example-six .next-button:hover {
    background-color: rgb(45, 45, 45);
}

.example-six .back-button:active,
.example-six .next-button:active {
    background-color: rgb(85, 85, 85);
}
</style>

<script>
import SeriProductEdit from "../SeriProduct/edit.vue";
import SeriProductAdd from "../SeriProduct/add.vue";
import SeriProductService from "@/services/SeriProductService";
// import WarrantyService from "@/services/WarrantyService";
import moment from 'moment'
import {
    ref
} from "vue";
import "vue-awesome-paginate/dist/style.css";
import 'mosha-vue-toastify/dist/style.css';
import {
    createToast
} from 'mosha-vue-toastify';
import removeAccents from 'remove-accents';
// import axios from 'axios'
export default {
    name: "Index",
    components: {
        SeriProductAdd,
        SeriProductEdit
    },
    data() {
        return {
            seriproduct: null,
            showEdit: null,
            showTrash: null,
            isShowEdit: false,
            isShowAdd: false,
            isShowTrash: false,
            Time: [],
            pageNumber: 1,
            Count: 1,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false,
            selectedType: '',
            startDate: null,
            endDate: null,
            intervalId: null
        }
    },
    created() {
        this.fetchSeriProduct();
        this.intervalId = setInterval(this.fetchSeriProduct, 5000);
        SeriProductService.getAll()
            .then((res) => {
                this.seriproduct = res.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })
    },
    beforeUnmount() {
        clearInterval(this.intervalId); // Dừng interval khi component bị hủy
    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        filterItem() {
            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.seriproduct.filter(item => item.type === filterType) : this.seriproduct
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.dealerSaleDate)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        resultCount() {
            return this.seriproductFilte && this.seriproductFilte.length
        },
        seriproductFilte() {
            if (this.query) {
                const normalizedQuery = removeAccents(this.query.toLowerCase());

                return this.seriproduct.filter((seriproduct) => {
                    return (
                        removeAccents(seriproduct.productName.toLowerCase()).includes(normalizedQuery) ||
                        removeAccents(seriproduct.productSeri.toLowerCase()).includes(normalizedQuery) ||
                        removeAccents(seriproduct.locationName.toLowerCase()).includes(normalizedQuery)
                    );
                });
            } else if (this.startDate || this.endDate) {
                let filterType = this.selectedType;
                let startDate = this.localizeDate(this.startDate);
                let endDate = this.localizeDate(this.endDate);

                const itemsByType = filterType ? this.seriproduct.filter(item => item.type === filterType) : this.seriproduct
                return itemsByType
                    .filter(item => {
                        const itemDate = new Date(item.dealerSaleDate)
                        if (startDate && endDate) {
                            return startDate <= itemDate && itemDate <= endDate;
                        }
                        if (startDate && !endDate) {
                            return startDate <= itemDate;
                        }
                        if (!startDate && endDate) {
                            return itemDate <= endDate;
                        }
                        return true;
                    })
            } else {
                return this.seriproduct;
            }

        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.seriproductFilte.slice(this.indexStart, this.indexEnd);
            } else {
                return this.seriproductFilte;
            }
        }

    },
    methods: {
        fetchSeriProduct() {
            SeriProductService.getOnChangePageSeri(this.currentPage)
            .then((res) => {
                this.Time = res.data.items;
                this.pageNumber = res.data.pageIndex;
                this.Count = res.data.count;
                console.log(this.Time);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })
        },
        localizeDate(date) {
            // Date picker uses ISO format (yyyy-mm-dd), which is UTC. The data
            // contains locale specific date strings (mm/dd/yyyy), which `Date`
            // parses with local time-zone offset instead of UTC. Normalize the
            // ISO date so we're comparing local times.
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {
                dateStyle: 'long'
            }).format(new Date(date))
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY')
            }
        },
        onClickHandler(page) {
            SeriProductService.getOnChangePageSeri(page)
            .then((res) => {
                this.Time = res.data.items;
                this.pageNumber = res.data.pageIndex;
                this.Count = res.data.count;
                console.log(this.Time);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })
        },
        onEdit(data) {
            this.showEdit = data;
            this.isShowEdit = true
            console.log(data);
        },
        back_to() {
            this.isShowEdit = false,
                this.isShowAdd = false,
                this.isShowTrash = false
        },
        onAdd() {
            this.isShowAdd = true
        },
        onTrash() {
            this.isShowTrash = true
        },
        getData(data) {
            this.Time.push(data);
            console.log(data);
            this.isShowAdd = false;
            this.$forceUpdate();

        },
        getDeleteData(data) {
            this.Time.push(data);
            console.log(data);
            this.isShowTrash = false;
            this.$forceUpdate();

        },
        getEdit(data) {
            for (let i = 0; i < this.Time.length; i++) {
                if (this.Time[i].id == data.id) {
                    this.Time[i] = data;
                    this.$forceUpdate();
                    break;
                }
            }

            console.log(this.Time);
            this.isShowEdit = false;
        },
        onDelete(item) {
            if (confirm("Are you sure you want to delete " + item.id)) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    SeriProductService.delete(item.id)
                        .then(response => {
                            console.log(response);
                            this.Time.splice(this.Time.findIndex(e => e.id == item.id), 1).push(response.data);
                            createToast({
                                title: 'Xóa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                }
            }
        }
    }

}
</script>
