import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

var app = createApp(App);

app.use(CKEditor).use(VueAwesomePaginate);

app.use(router).mount('#app');