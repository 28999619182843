<template>
<div class="card-header">
    <h5>Danh sách khách hàng liên hệ</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow" v-if="isShowEdit == false && isShowAdd == false">

                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">Mã</th>
                                        <th class="text-center">Tên khách hàng</th>
                                        <th class="text-center">Email</th>
                                        <th class="text-center">Số điện thoại</th>
                                        <th class="text-center">Thông tin liên hệ</th>
                                        <th class="text-center">Thời gian</th>
                                        <th class="text-center">Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody v-if="query">
                                    <tr v-for="item in contactFilte" :key="item">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.email }}</td>
                                        <td>{{ item.phone }}</td>
                                        <td>{{ item.note }}</td>
                                        <td>{{ format_date(item.createdDate) }}</td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody v-else>
                                    <tr v-for="item in paginated" :key="item">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.email }}</td>
                                        <td>{{ item.phone }}</td>
                                        <td>{{ item.note }}</td>
                                        <td>{{ format_date(item.createdDate) }}</td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="column is-12">
                            <div class="example-six">
                                <vue-awesome-paginate :total-items="resultCount" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                                    <template #prev-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>

                                    <template #next-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>
                                </vue-awesome-paginate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.example-six .pagination-container {
    column-gap: 10px;
    align-items: center;
}

.example-six .paginate-buttons {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: black;
    border: 1px solid;
}

.example-six .back-button,
.example-six .next-button {
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.example-six .active-page {
    background-color: #20c997;
    color: white;
}

.example-six .paginate-buttons:hover {
    background-color: #f5f5f5;
}

.example-six .active-page:hover {
    background-color: #20c997;
}

.example-six .back-button svg {
    transform: rotate(180deg) translateY(-2px);
}

.example-six .next-button svg {
    transform: translateY(2px);
}

.example-six .back-button:hover,
.example-six .next-button:hover {
    background-color: rgb(45, 45, 45);
}

.example-six .back-button:active,
.example-six .next-button:active {
    background-color: rgb(85, 85, 85);
}
</style>

<script>
import ContactService from "../../services/ContactService";
import {
    ref
} from "vue";
import "vue-awesome-paginate/dist/style.css";
import moment from 'moment'
// import 'mosha-vue-toastify/dist/style.css';
// import { createToast } from 'mosha-vue-toastify';
export default {
    name: "Index",
    data() {
        return {
            contact: null,
            showEdit: null,
            isShowEdit: false,
            isShowAdd: false,
            getShowAdd: null,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false
        };
    },

    mounted() {
        ContactService.getAll()
            .then((res) => {
                this.contact = res.data;
                console.log(this.contact);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            });
    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        resultCount() {
            return this.contact && this.contact.length
        },
        contactFilte() {
            if (this.query) {
                return this.contact.filter((contact) => {
                    return (
                        contact.title
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.contact;
            }

        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.contact.slice(this.indexStart, this.indexEnd);
            } else {
                return this.contact;
            }
        }
    },

    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        getTokenConfig() {
            let token = JSON.parse(sessionStorage.getItem("token"));
            const config = {
                headers: {
                    Authorization: 'Bearer' + token
                }
            }
            return config;
        },
        onClickHandler(page) {
            this.current = page.current;
        },
        onEdit(data) {
            this.showEdit = data;
            this.isShowEdit = true;
            console.log(this.contact);
        },
        back_to() {
            (this.isShowEdit = false), (this.isShowAdd = false);
        },
        onAdd() {
            this.isShowAdd = true;
            this.getShowAdd = this.contact;
        },
        getData(data) {
            this.contact.push(data);
            console.log(data);
            this.isShowAdd = false;
        },
        getEdit(data) {
            for (let i = 0; i < this.contact.length; i++) {
                if (this.contact[i].id == data.id) {
                    this.contact[i] = data;
                    console.log(this.contact[i]);
                }
            }
            console.log(this.contact);
            this.isShowEdit = false;
        },
        onDelete(item) {
            console.log(item.id);
            if (confirm("Bạn có chắc muốn xóa contact mã " + item.code)) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    ContactService.delete(item.id)
                        .then((response) => {
                            console.log(response);
                            this.contact
                                .splice(
                                    this.contact.findIndex((e) => e.id == item.id),
                                    1
                                )
                                .push(response.data);

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                }
            }
        },

    }
};
</script>
