import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class GiftService {
    getAll() {
        return http.get("/Gift",httpOptions);
    }
    get(id) {
        return http.get(`/Gift/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/Gift", data,httpOptions);
    }
    update(data) {
        return http.post(`/Gift/PutGift`, data,httpOptions);
    }
    delete(id) {
        return http.delete(`/Gift/${id}`,httpOptions);
    }
}
export default new GiftService();