<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới giá theo màu và kích thước</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="item.productId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in products" :key="items.id" :selected="item.productId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tùy chọn</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="attributeId" v-model="item.attributeId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in attrbutes" :key="items.id" :selected="item.attributeId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá trị tùy chọn</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="valueAttributeId" v-model="item.valueAttributeId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in valueattributes" :key="items.id" :selected="item.valueAttributeId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Quà tặng:</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="giftId" v-model="item.giftId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in gifts" :key="items.id" :selected="item.giftId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Giá</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="number" class="form-control" id="price" placeholder="" v-model="item.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                        <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                        <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                        </p>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ItemService from '@/services/ItemService';
import ProductService from '@/services/ProductService';
import GiftService from '@/services/GiftService';
import AttributeService from '@/services/AttributeService';
import ValueAttributeService from '@/services/ValueAttributeService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            products: null,
            gifts: null,
            attrbutes: null,
            valueattributes: null,
            url: null,
            ID: null,
            item: {
                id: null,
                productId: "",
                giftId: "",
                attributeId: "",
                valueAttributeId: "",
                price: "",
                status: true
            },

            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },
            SameError: {
                text: "",
                status: false,
            },
            SameSuccess: {
                text: "",
                status: false,
            },
            NullError: {
                text: "",
                status: false,
            },
            NullSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ItemService.getAll().then((res) => {
            this.a = res.data
        })
        ProductService.getAll()
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        AttributeService.getAll()
            .then((res) => {
                this.attrbutes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        ValueAttributeService.getAll()
            .then((res) => {
                this.valueattributes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        GiftService.getAll()
            .then((res) => {
                this.gifts = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        onSubmitForm() {
            var proSame;
            var colorSame;
            var sizeSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.item.productId == element.productId & this.item.attributeId == element.attributeId & this.item.valueAttributeId == element.valueAttributeId) {
                    proSame = element.productId;
                    colorSame = element.attributeId;
                    sizeSame = element.valueAttributeId;
                    break;
                }

            }

            if (this.item.productId == proSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.item.attributeId == colorSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.item.valueAttributeId == sizeSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else {
                this.SameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.item.price.length == 0) {
                this.priceError = {
                    text: "price cannot be empty!",
                    status: true
                }
                this.priceSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.item.price.length > 0) {
                this.priceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.priceSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    ItemService.create(this.item)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.item.id = this.ID;
                            this.item.status = true;
                            this.$emit("ShowData", this.item);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Thêm mới thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại, đã có sản phẩm màu sắc kích thước này tồn tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
