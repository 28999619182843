<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa seri Sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="seriproducts">
                <input type="hidden" v-model="seriproducts.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Seri sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productSeri" placeholder="" v-model="seriproducts.productSeri" :class="{ error: productSeriError.status, success: productSeriSuccess.status }">
                                <p class="text-error" v-if="productSeriError.status">{{ productSeriError.text }}</p>
                                <p class="success-text" v-if="productSeriSuccess.status">{{ productSeriSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control basic" name="" id="productId" v-model="seriproducts.productId" :class="{ error: productNameError.status, success: productNameSuccess.status }">
                                    <option value="">Choose</option>
                                    <option v-for="item in proCate" :key="item.id" v-bind:value="item.id">
                                        {{ item.name}}</option>
                                </select>
                                <p class="text-error" v-if="productNameError.status">{{ productNameError.text }}</p>
                                <p class="success-text" v-if="productNameSuccess.status">{{ productNameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Thành phố</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control basic" name="" id="cityId" v-model="seriproducts.cityId" @change="changeCity($event)">
                                    <option value="" :selected="true">Choose</option>
                                    <option v-for="item in city" :key="item.id" v-bind:value="item.id" v-bind:id="item.id">
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control basic" name="" id="locationId" v-model="seriproducts.locationId">
                                    <option value="" :selected="true">Choose</option>
                                    <option v-for="item in location" :key="item.id" :selected="seriproducts.locationId === item.id" v-bind:value="item.id">
                                        Mã đại lý: {{ item.code }}
                                        - Tên đại lý: <p v-html="item.description"></p>
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày xuất kho cho đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="dealerSaleDate" placeholder="" v-model="seriproducts.dealerSaleDate">
                            </div>
                        </div>
                    </div>
                </div>

                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="seriproducts.status" :value="true" :checked="seriproducts.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Đã bảo hành
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="seriproducts.status" :value="false" :checked="seriproducts.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Chờ bảo hành </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import SeriProductService from '@/services/SeriProductService';
import ProductService from "@/services/ProductService";
import LocationService from "@/services/LocationService";
import CityService from '@/services/CityService';
import moment from 'moment'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['seriproduct'],

    data() {

        return {
            seriproducts: this.seriproduct,
            url: null,
            ishowImage: false,
            currentImage: undefined,
            city: null,
            proCate: null,
            location: null,
            message: "",
            productNameError: {
                text: "",
                status: false,
            },
            productNameSuccess: {
                text: "",
                status: false,
            },
            productSeriError: {
                text: "",
                status: false,
            },
            productSeriSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ProductService.getWaranty()
            .then((res) => {
                this.proCate = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            }),
        CityService.getAll()
            .then((res) => {
                this.city = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            }),
        LocationService.getCity(this.seriproducts.cityId).then((res) => {
                this.location = res.data
            })
    },
    methods: {
        onLocation(id) {
            LocationService.getCity(id).then((res) => {
                this.location = res.data
            })
        },
        changeCity(event) {
            const pro = event.target.options[event.target.options.selectedIndex].id;
            this.onLocation(pro);
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        onSubmitEditForm() {
            if (this.seriproducts.productId.length == 0) {
                this.productNameError = {
                    text: "productName cannot be empty",
                    status: true
                };
                this.productNameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.seriproducts.productId.length > 0) {
                this.productNameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productNameError = {
                    text: "",
                    status: false
                }
            } else {
                this.productNameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.seriproducts.productSeri.length == 0) {
                this.productSeriError = {
                    text: "productSeri cannot be empty",
                    status: true
                };
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.seriproducts.productSeri.length > 0) {
                this.productSeriSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productSeriError = {
                    text: "",
                    status: false
                }
            } else {
                this.productSeriSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.productSeriSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    SeriProductService.update(this.seriproducts)
                        .then((res) => {
                            console.log(res);
                            this.$emit("ShowEditData", this.seriproducts);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.seriproducts);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
