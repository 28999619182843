import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class PaymentService {
    
    getAll() {
        return http.get("/PaymentInformationModels",httpOptions);
    }
}
export default new PaymentService();