<template>
<div class="card-header">
    <h5>Quản lý Bảo hành Seo</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow" v-if="isShowEdit == false && isShowAdd == false">

                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <a v-on:click.prevent="onAdd" class="btn btn-success mb-3">Thêm Mới +</a>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">Title Seo</th>
                                        <th class="text-center">KeyWords</th>
                                        <th class="text-center">Mô tả Seo</th>
                                        <th class="text-center">Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody v-if="query">
                                    <tr v-for="item in warrantyseoFilte" :key="item">
                                        <td class="text-center">{{ item.titleSeo }}</td>
                                        <td>{{ item.metaKeyWords }}</td>
                                        <td>{{ item.metaDescription }}</td>

                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody v-else>
                                    <tr v-for="item in paginated" :key="item">
                                        <td class="text-center">{{ item.titleSeo }}</td>
                                        <td>{{ item.metaKeyWords }}</td>
                                        <td>{{ item.metaDescription }}</td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-warning mr-2" data-placement="top" title="Chỉnh sửa" data-original-title="Edit" style="padding: 12px" v-on:click="onEdit(item)"><i class="fa-regular fa-pen-to-square fa-lg"></i>
                                            </a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="column is-12">
                            <nav class="pagination is-right" role="navigation" aria-label="pagination">
                                <ul class="pagination pagination-sm m-0 float-right" v-if="totalPaginate > 1">
                                    <li class="page-item"><a class="page-link" @click="prev" href="javascript:void(0)">«</a></li>
                                    <li class="page-item" v-for="item in totalPaginate" :key="item">
                                        <span v-on:click.prevent="onCurrent(item)" v-bind:class="{ isActive: (item === current), 'text-dark': isActive == false }" class="btn-paginate pagination-link go-to has-text-orange page-link" style="border-radius: 0;margin: 0;" aria-label="Goto page 1">{{ item }}</span>
                                    </li>
                                    <li class="page-item"><a class="page-link" @click="next" href="javascript:void(0)">»</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <a href="" v-if="isShowEdit == true || isShowAdd == true" v-on:click.prevent="back_to"><svg xmlns="http://www.w3.org/2000/svg" width="16" style="width: 32px; height: 32px" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg></a>
                <WarrantySeoEdit :warrantyseo="showEdit" v-if="isShowEdit == true" @ShowEditData="getEdit($event)" />
                <WarrantySeoAdd :warrantyseo="getShowAdd" v-if="isShowAdd == true" @ShowData="getData($event)" />
            </div>
        </div>
    </div>
</div>
</template>

<style>
.pagination-list {
    list-style: none;
    display: flex;
}

.btn-paginate {
    margin-inline: 5px;
    cursor: pointer;
    border-style: groove;
    border-radius: 100%;
    text-align: center;
    padding: 10%;
    color: #000;

}

.isActive {
    background-color: #80b500;
    color: #ffff;
}
</style>

<script>
import WarrantySeoService from "../../services/WarrantySeoService";
import WarrantySeoEdit from "../WarrantySeo/edit.vue";
import WarrantySeoAdd from "../WarrantySeo/add.vue";

import 'mosha-vue-toastify/dist/style.css';
import { createToast } from 'mosha-vue-toastify';
export default {
    name: "Index",
    components: {
        WarrantySeoEdit,
        WarrantySeoAdd,
    },
    data() {
        return {
            warrantyseo: null,
            showEdit: null,
            isShowEdit: false,
            isShowAdd: false,
            getShowAdd: null,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false
        };
    },

    mounted() {
        WarrantySeoService.getAll()
            .then((res) => {
                this.warrantyseo = res.data;
                console.log(this.warrantyseo);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            });
    },
    computed: {
        resultCount() {
            return this.warrantyseo && this.warrantyseo.length
        },
        warrantyseoFilte() {
            if (this.query) {
                return this.warrantyseo.filter((warrantyseo) => {
                    return (
                        warrantyseo.name
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.warrantyseo;
            }

        },
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        totalPaginate() {
            if (this.resultCount % 5 == 0) {
                return Math.floor(this.resultCount / 5);
            } else {
                return Math.floor(this.resultCount / 5) + 1;
            }
        },
        paginated() {
            console.log(this.resultCount);
            if (this.resultCount > 5) {
                return this.warrantyseo.slice(this.indexStart, this.indexEnd, this.totalPaginate);
            } else {
                return this.warrantyseo;
            }
        }
    },

    methods: {

        getTokenConfig() {
            let token = JSON.parse(sessionStorage.getItem("token"));
            const config = {
                headers: {
                    Authorization: 'Bearer' + token
                }
            }
            return config;
        },

        onCurrent(item) {
            this.isActive = true
            return this.current = item;
        },

        prev() {
            this.current--;
            if (this.current == 0) {
                return this.current = 1;
            }
        },
        next() {
            this.current++;
            if (this.current > this.totalPaginate) {
                return this.current = this.totalPaginate;
            }
        },
        onEdit(data) {
            this.showEdit = data;
            this.isShowEdit = true;
            console.log(this.warrantyseo);
        },
        back_to() {
            (this.isShowEdit = false), (this.isShowAdd = false);
        },
        onAdd() {
            this.isShowAdd = true;
            this.getShowAdd = this.warrantyseo;
        },
        getData(data) {
            this.warrantyseo.push(data);
            console.log(data);
            this.isShowAdd = false;
        },
        getEdit(data) {
            for (let i = 0; i < this.warrantyseo.length; i++) {
                if (this.warrantyseo[i].id == data.id) {
                    this.warrantyseo[i] = data;
                    console.log(this.warrantyseo[i]);
                }
            }
            console.log(this.warrantyseo);
            this.isShowEdit = false;
        },
        onDelete(item) {
            console.log(item.id);
            if (confirm("Bạn có chắc muốn xóa warrantyseo mã " + item.id)) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    WarrantySeoService.delete(item.id)
                        .then((response) => {
                            console.log(response);
                            this.warrantyseo
                                .splice(
                                    this.warrantyseo.findIndex((e) => e.id == item.id),
                                    1
                                )
                                .push(response.data);
                            createToast({
                                title: 'Xóa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                }
            }
        },

    }
};
</script>
