import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class UserLandingService {
    getAll() {
        return http.get("/UserLanding",httpOptions);
    }
    get(id) {
        return http.get(`/UserLanding/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/UserLanding", data,httpOptions);
    }
    update(data) {
        return http.post(`/UserLanding/PutUserLanding`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/UserLanding/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/UserLanding/TrashUserLanding",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/UserLanding/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/UserLanding/RepeatUserLanding`, data,httpOptions);
    }
}
export default new UserLandingService();