<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa câu hỏi dịch vụ</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitEditForm" v-if="questionservices">
                <input type="hidden" v-model="questionservices.id" />
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã
                                :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="questionservices.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="codeError.status">
                                    <i>{{ codeError.text }}</i>
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="codeSuccess.status">
                                    {{ codeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="title" placeholder="" v-model="questionservices.title" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <ckeditor :editor="editor" :config="editorConfig" tag-name="textarea" v-model="questionservices.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>

                                <p class="text-danger mb-0 mt-2" v-if="descriptionError.status">
                                    {{ descriptionError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="descriptionSuccess.status">
                                    {{ descriptionSuccess.text }}
                                </p>

                            </div>
                        </div>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái :</label>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="questionservices.status" :value="true" :checked="questionservices.status === true" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Action
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="questionservices.status" :value="false" :checked="questionservices.status === false || questionservices.status === ''" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            No Action
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh mục</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="serviceDetailId" v-model="questionservices.serviceDetailId">
                                    <option value="">Chọn</option>
                                    <option v-for="item in categorys" :key="item.id" :selected="questionservices.serviceDetailId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="questionservices.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>

                                <p class="text-danger mb-0 mt-2" v-if="descriptionError.status">
                                    {{ descriptionError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="descriptionSuccess.status">
                                    {{ descriptionSuccess.text }}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import QuestionSevService from "../../services/QuestionSevService";
import ServiceDetailService from '@/services/ServiceDetailService';
import {
    ClassicEditor
} from '@ckeditor/ckeditor5-editor-classic';

import {
    Alignment
} from '@ckeditor/ckeditor5-alignment'; // Importing the package.
import {
    Autoformat
} from '@ckeditor/ckeditor5-autoformat';
import {
    BlockQuote
} from '@ckeditor/ckeditor5-block-quote';
import {
    CloudServices
} from '@ckeditor/ckeditor5-cloud-services';
import {
    Essentials
} from '@ckeditor/ckeditor5-essentials';
import {
    Heading
} from '@ckeditor/ckeditor5-heading';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
} from '@ckeditor/ckeditor5-image';
import {
    Indent
} from '@ckeditor/ckeditor5-indent';
import {
    Link, LinkImage
} from '@ckeditor/ckeditor5-link';
import {
    List
} from '@ckeditor/ckeditor5-list';
import {
    MediaEmbed
} from '@ckeditor/ckeditor5-media-embed';
import {
    Paragraph
} from '@ckeditor/ckeditor5-paragraph';
import {
    PasteFromOffice
} from '@ckeditor/ckeditor5-paste-from-office';
import {
    Table,
    TableToolbar
} from '@ckeditor/ckeditor5-table';
import {
    TextTransformation
} from '@ckeditor/ckeditor5-typing';
import {
    SourceEditing
} from '@ckeditor/ckeditor5-source-editing';
import {
    GeneralHtmlSupport
} from '@ckeditor/ckeditor5-html-support';
import {
    CKFinder
} from '@ckeditor/ckeditor5-ckfinder';
import {
    UploadAdapter
} from '@ckeditor/ckeditor5-adapter-ckfinder';
import {
    Base64UploadAdapter,
    SimpleUploadAdapter
} from '@ckeditor/ckeditor5-upload';
import {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript
} from '@ckeditor/ckeditor5-basic-styles';
import {
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize
} from '@ckeditor/ckeditor5-font';
import {
    CodeBlock
} from '@ckeditor/ckeditor5-code-block';
import {
    Highlight
} from '@ckeditor/ckeditor5-highlight';
import {
    HorizontalLine
} from '@ckeditor/ckeditor5-horizontal-line';
import {
    RemoveFormat
} from '@ckeditor/ckeditor5-remove-format';
import {
    PageBreak
} from '@ckeditor/ckeditor5-page-break';
import {
    Style
} from '@ckeditor/ckeditor5-style';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["questionservice"],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    Heading,
                    Highlight,
                    CodeBlock,
                    BlockQuote,
                    CloudServices,
                    Essentials,
                    Bold,
                    Italic,
                    PageBreak,
                    Underline, Strikethrough, Subscript, Superscript,
                    FontColor, FontBackgroundColor, FontFamily, FontSize,
                    Link,
                    Style,
                    ImageInsert,
                    Paragraph,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    LinkImage,
                    Table,
                    TableToolbar,
                    SourceEditing,
                    RemoveFormat,
                    GeneralHtmlSupport,
                    CKFinder,
                    HorizontalLine,
                    Base64UploadAdapter,
                    SimpleUploadAdapter,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    UploadAdapter,
                    Indent,
                    List,
                    MediaEmbed,
                    PasteFromOffice,
                    TextTransformation
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'ckfinder',
                        'insertImage',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'alignment',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        'highlight',
                        'horizontalLine',
                        'pageBreak',
                        'removeFormat',
                        'strikethrough',
                        'underline',
                        'style',
                        'sourceEditing'
                    ]
                },
                ckfinder: {
                    uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
                    filebrowserBrowseUrl: '/ckfinder/browser',
                    filebrowserImageBrowseUrl: '/ckfinder/browser?type=Images',
                    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
                    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images'
                },
                heading: {
                    options: [{
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },

                language: 'vi',
                highlight: {
                    options: [{
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker'
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        // 'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:alignLeft',
                        'imageStyle:alignBlockLeft',
                        'imageStyle:alignBlockRight',
                        'toggleImageCaption',
                        'linkImage'
                    ]
                },
                style: {
                    definitions: [{
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                    ]
                },
                htmlSupport: {
                    allow: [{
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true
                    }]
                },
                // Configuration of the FontSize plugin.
                fontSize: {
                    options: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
                    ],
                    supportAllValues: true
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                fontFamily: {
                    options: [
                        'default',
                        'indieflowerregular',
                        'Arial, sans-serif',
                        'Verdana, sans-serif',
                        'Trebuchet MS',
                        'Apple Color Emoji',
                        'Segoe UI Emoji',
                        'Segoe UI Symbol',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [{
                        name: 'extraProvider',
                        url: /^example\.com\/media\/(\w+)/,

                    }, ]

                },
                licenseKey: ''
            },
            message: "",
            currentImage: undefined,
            url: null,
            questionservices: this.questionservice,
            ishowImage: false,
            categorys: null,
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
        };
    },
    mounted() {
        ServiceDetailService.getAll()
            .then((res) => {
                this.categorys = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        onSubmitEditForm() {

            if (this.questionservices.code.length == 0) {
                this.codeError = {
                    text: "*Mã không được để trống",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.questionservices.code.length < 4 || this.questionservices.code.length > 6) {
                this.codeError = {
                    text: "*Mã phải từ 4 đến 6 kí tự",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.questionservices.code.length > 4 || this.questionservices.code.length < 6) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.codeError = {
                    text: "",
                    status: false,
                };
            } else {
                this.codeError = {
                    text: "",
                    status: false,
                };
            }

            if (this.questionservices.title.length == 0) {
                this.nameError = {
                    text: "*Tên không được để trống!",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.questionservices.title.length > 0) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }
            if (this.questionservices.description.length == 0) {
                this.descriptionError = {
                    text: "Mô tả không được để trống",
                    status: true,
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.questionservices.description.length > 6 || this.questionservices.description.length < 50) {
                this.descriptionSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            } else {
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {

                    QuestionSevService.update(this.questionservices)
                        .then((res) => {
                            //Perform Success Action
                            this.questionservices = res.data;
                            this.questionservices.serviceName = res.data.serviceName;
                            this.$emit("ShowEditData", this.questionservices);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.questionservices);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },

    },
};
</script>
