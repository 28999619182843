<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa ảnh sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="imageproducts">
                <input type="hidden" v-model="imageproducts.id">

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="imageproducts.productId" :class="{ error: productIdError.status, success: productIdSuccess.status }">
                                    <option value="">Chọn</option>
                                    <option v-for="item in product" :key="item.id" :selected="imageproducts.productId === item.id" v-bind:value="item.id">{{
                                                            item.name
                                                        }}</option>
                                </select>
                                <p class="text-error" v-if="productIdError.status">{{ productIdError.text }}</p>
                                <p class="success-text" v-if="productIdSuccess.status">{{ productIdSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" name="file" ref="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img :src="'https://spectrababy.com.vn/dataApi/images/' + imageproducts.imageName" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" v-if="ishowImage == false" />
                                <img v-bind:src="url" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" v-if="ishowImage == true" />
                            </div>
                        </div>
                    </div>
                </div>

                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="imageproducts.status" :value="true" :checked="imageproducts.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="imageproducts.status" :value="false" :checked="imageproducts.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ImageProductService from '@/services/ImageProductService';
import UploadService from '@/services/UploadService';
import ProductService from '@/services/ProductService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['imageproduct'],

    data() {

        return {
            imageproducts: this.imageproduct,
            url: null,
            productName: null,
            ishowImage: false,
            currentImage: undefined,
            old: "https://spectrababy.com.vn/dataApi/images/" + this.imageproduct.imageName,
            message: "",
            product: null,
            productIdError: {
                text: "",
                status: false,
            },
            productIdSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ProductService.getAll()
            .then((res) => {
                this.product = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // this.imageproducts.imageName = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },
        uploadNewFile() {
            if (this.currentImage) { // Kiểm tra xem có chỉnh sửa file ảnh không
                // Upload file mới
                UploadService.upload(this.currentImage)
                    .then((response) => {
                        this.message = response.data.message;

                        // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                        this.imageproducts.imageName = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file

                        // Sau đó lưu thông tin sản phẩm vào database
                        return ImageProductService.update(this.imageproducts);
                    })
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            } else {
                // Nếu không chỉnh sửa file ảnh, chỉ cần cập nhật thông tin sản phẩm
                ImageProductService.update(this.imageproducts)
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },

        handleSuccessResponse(res) {
            // Thực hiện hành động khi thành công
            this.ID = res.data.id;
            this.imageproducts.id = this.ID;
            // this.imageproducts.status = true;
            this.imageproducts.productName = res.data.productName;

            this.$emit("ShowEditData", this.imageproducts);

            // Gửi dữ liệu hình ảnh lên để hiển thị trong component cha (nếu cần)
            this.$emit("ShowData", this.imageproducts);

            // Hiển thị thông báo thành công
            createToast({
                title: 'Chỉnh sửa thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },

        handleError(error) {
            console.log(error);
            this.message = "Đã xảy ra lỗi khi sửa thông tin sản phẩm.";
            createToast({
                title: 'Chỉnh sửa thất bại',
            }, {
                position: 'top-right',
                type: 'danger',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },

        onSubmitEditForm() {
            if (this.imageproducts.productId.length == 0) {
                this.productIdError = {
                    text: "productId cannot be empty!",
                    status: true
                }
                this.productIdSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.imageproducts.productId.length > 0) {
                this.productIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.productIdSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.productIdSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.currentImage) {
                        UploadService.checkFileExists(this.imageproducts.imageName, 'images') // Thay 'images' với thư mục con thực tế
                            .then(response => {
                                if (response.data.exists) {
                                    // Nếu file tồn tại, xóa nó
                                    return UploadService.deleteFile(this.imageproducts.imageName, 'images');
                                } else {
                                    // Nếu file không tồn tại, tiếp tục với upload file mới
                                    return Promise.resolve();
                                }
                            })
                            .finally(() => {
                                // Tiếp tục upload file mới
                                this.uploadNewFile();
                            });
                    } else {
                        // Nếu không có file cũ để kiểm tra, tiếp tục upload file mới
                        this.uploadNewFile();
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
