<template>
<nav class="main-header navbar navbar-expand navbar-white navbar-light" style="margin-left: 0 !important;">
    <!-- Left navbar links -->
    <ul class="navbar-nav" style="width: 100%;">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i
                    class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Home</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Contact</a>
        </li>
        <div class="alert alert-warning" style="margin: 0px auto !important;">
            <strong><i class="fa-solid fa-triangle-exclamation fa-beat mr-2"></i>Chỉnh sửa thêm mới thông tin xong hãy nhớ đăng xuất tài khoản!</strong>
        </div>
        
    </ul>
</nav>
</template>
