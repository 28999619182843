import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class UserLandingService {
    getAll() {
        return http.get("/feedback",httpOptions);
    }
    get(id) {
        return http.get(`/feedback/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/feedback", data,httpOptions);
    }
    update(data) {
        return http.post(`/feedback/Putfeedback`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/feedback/${id}`,httpOptions);
    }
    
}
export default new UserLandingService();