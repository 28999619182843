import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class SeriProductService {
    
    getAll() {
        return http.get("/SeriProduct",httpOptions);
    }
    getPageSeri() {
        return http.get("/SeriProduct/SeriProductPage",httpOptions);
    }
    getOnChangePageSeri(pageNumber) {
        return http.get(`/SeriProduct/SeriProductPage?page=${pageNumber}`,httpOptions);
    }
    get(id) {
        return http.get(`/SeriProduct/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/SeriProduct", data,httpOptions);
    }
    update(data) {
        return http.post(`/SeriProduct/PutSeriProduct`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/SeriProduct/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new SeriProductService();