<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h4 class="text-center"> Thông tin đơn hàng Spectra.vn</h4>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="row">
            <div class="card" style="width: 100%;">
                <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="orders && orderDetail">
                    <div class="widget-content widget-content-area" style="padding-top: 50px;padding-bottom: 33px;">
                        <div class="row">
                            <div class="col-md-8" style="padding-left: 40px;">
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Mã đơn hàng:</b></div>
                                    <div class="col-md-8">{{ orders.code }}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Tên khách hàng:</b></div>
                                    <div class="col-md-8">{{ orders.name }}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Số ĐT:</b></div>
                                    <div class="col-md-8">{{ orders.phone }}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Địa chỉ:</b></div>
                                    <div class="col-md-8">{{ orders.address }}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Email:</b></div>
                                    <div class="col-md-8">{{ orders.email }}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-3"><b>Ghi chú:</b></div>
                                    <div class="col-md-8">{{ orders.note }}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>Chỉnh sửa trạng thái</h4>
                                </div>
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <fieldset class="form-group mb-4">
                                        <div class="row">
                                            <label class="col-3" style="margin-top: 10px;">
                                                Trạng thái
                                            </label>

                                            <div class="col-8">

                                                <select class="form-control  basic" v-if="orders.status == 0" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1">Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2">
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3">
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4">
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>

                                                </select>

                                                <select class="form-control  basic" v-else-if="orders.status == 1" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2">
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3">
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4">
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                </select>

                                                <select class="form-control  basic" v-else-if="orders.status == 2" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2" disabled>
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3">
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4" disabled>
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                </select>
                                                <select class="form-control  basic" v-else-if="orders.status == 3" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2" disabled>
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3" disabled>
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4" disabled>
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5">
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                </select>
                                                <select class="form-control  basic" v-else-if="orders.status == 4" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2" disabled>
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3" disabled>
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4" disabled>
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                </select>
                                                <select class="form-control  basic" v-else-if="orders.status == 7" v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2" disabled>
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3" disabled>
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4" disabled>
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                    <option :selected="orders.status === 7" value="7" disabled>
                                                        Khách hủy giao dịch VNPAY
                                                    </option>
                                                </select>
                                                <select class="form-control  basic" v-else v-model="orders.status" id="status">
                                                    <option :selected="orders.status === 0" value="0" disabled>Chưa xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 1" value="1" disabled>Xác nhận đơn hàng
                                                    </option>
                                                    <option :selected="orders.status === 2" value="2" disabled>
                                                        Đang giao hàng</option>
                                                    <option :selected="orders.status === 3" value="3" disabled>
                                                        Giao hàng thành công</option>
                                                    <option :selected="orders.status === 4" value="4" disabled>
                                                        Đã hủy
                                                    </option>
                                                    <option :selected="orders.status === 5" value="5" disabled>
                                                        Trả hàng
                                                    </option>
                                                    <option :selected="orders.status === 6" value="6" disabled>
                                                        Phê duyệt thanh toán
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="form-group row" style="margin-top: -12px;">
                                        <div class="col-sm-10">
                                            <button type="submit" class="btn btn-primary mt-3">Lưu lại</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <input type="hidden" v-model="orders.id">
                    <input type="hidden" v-model="orders.code">
                    <input type="hidden" v-model="orders.name">
                    <input type="hidden" v-model="orders.email">
                    <input type="hidden" v-model="orders.phone">
                    <input type="hidden" v-model="orders.address">
                    <input type="hidden" v-model="orders.note">
                    <input type="hidden" v-model="orders.accountUserId">
                    <input type="hidden" v-model="orders.totalAmount">
                    <input type="hidden" v-model="orders.totalQuantity">

                    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                        <div class="card-body table-responsive" style="height: 553px;">
                            <table class="table table-head-fixed text-pre-line table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Mã sản phẩm
                                        </th>
                                        <th>
                                            Tên sản phẩm
                                        </th>
                                        <th>
                                            Quà tặng hoặc voucher
                                        </th>
                                        <th>
                                            Số lượng
                                        </th>
                                        <th>
                                            Màu hoặc kích thước
                                        </th>
                                        <th>
                                            Thương hiệu
                                        </th>
                                        <th>
                                            Giá
                                        </th>
                                        <th>
                                            Tổng
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(item, index) in orderDetail" :key="item.id">
                                        <td>
                                            {{ index +=1 }}
                                        </td>

                                        <td>
                                            {{ item.productCode }}
                                        </td>
                                        <td>
                                            {{ item.productName }}
                                        </td>
                                        <td>
                                            {{ item.gift }}
                                        </td>
                                        <td>
                                            {{ item.quantity }}
                                        </td>
                                        <td>
                                            {{ item.brand }}
                                        </td>
                                        <td>
                                            Spectra
                                        </td>
                                        <td>
                                            {{ formatPrice(item.price ) }} VNĐ
                                        </td>
                                        <td>
                                            {{ formatPrice((item.price * item.quantity) - item.discountVoucher ) }} VNĐ
                                        </td>

                                    </tr>
                                    <tr style="text-align: right;">
                                        <td colspan="6"> <b>Phí ship:</b> 0 VND</td>
                                    </tr>
                                    <tr style="text-align: right;">
                                        <td colspan="6"> <b>Tổng tiền:</b>{{ formatPrice(orders.totalAmount) }} VNĐ</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>

        </div>

    </div>
</div>
</template>

<script>
import OrderDetailService from '@/services/OrderDetailService'
import OrderServices from '@/services/OrderServices'
export default {
    props: ['order'],
    data() {
        return {
            orders: this.order,
            orderDetail: null
        }
    },
    created() {
        OrderDetailService.get(this.order.id).then((res) => {
            this.orderDetail = res.data;
            console.log(this.orderDetail)
        })
    },
    methods: {
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        onSubmitEditForm() {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                OrderServices.update(this.orders)
                    .then((res) => {
                        console.log(res.data);
                        this.$emit("ShowEditData", this.orders);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {})
            }else {
                alert("Bạn không có quyền sử dụng chức năng này");
            }
        }
    }

}
</script>
