<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa giá theo màu sắc</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="itemz">
                <input type="hidden" v-model="itemz.id">
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="itemz.productId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in products" :key="items.id" :selected="itemz.productId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tùy chọn</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="attributeId" v-model="itemz.attributeId">
                                    <option v-bind:value="null">Chọn</option>
                                    <option v-for="items in attributes" :key="items.id" :selected="itemz.attributeId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá trị tùy chọn</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="valueAttributeId" v-model="itemz.valueAttributeId">
                                    <option v-bind:value="null">Chọn</option>
                                    <option v-for="items in valueattributes" :key="items.id" :selected="itemz.valueAttributeId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style="display: flex;">
                    <div class="col-lg-12" style="padding: 0;">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Quà tặng:</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="giftId" v-model="itemz.giftId">
                                    <option value="">Chọn</option>
                                    <option v-for="items in gifts" :key="items.id" :selected="itemz.giftId === items.id" v-bind:value="items.id">{{
                                                        items.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Giá</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="number" class="form-control" id="price" placeholder="" v-model="itemz.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                        <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                        <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                        </p>
                    </div>
                </div>

                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="itemz.status" :value="true" :checked="itemz.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="itemz.status" :value="false" :checked="itemz.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ItemService from '@/services/ItemService';
import ProductService from '@/services/ProductService';
import GiftService from '@/services/GiftService';
import AttributeService from '@/services/AttributeService';
import ValueAttributeService from '@/services/ValueAttributeService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['item'],

    data() {

        return {
            itemz: this.item,
            url: null,
            a: null,
            productName: null,
            colorName: null,
            sizeName: null,
            products: null,
            gifts: null,
            attributes: null,
            valueattributes: null,
            ishowImage: false,
            message: "",

            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },
            SameError: {
                text: "",
                status: false,
            },
            SameSuccess: {
                text: "",
                status: false,
            },
            NullError: {
                text: "",
                status: false,
            },
            NullSuccess: {
                text: "",
                status: false,
            }

        }

    },
    mounted() {
        ItemService.getAll().then((res) => {
            this.a = res.data
        })
        ProductService.getAll()
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        AttributeService.getAll()
            .then((res) => {
                this.attributes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        ValueAttributeService.getAll()
            .then((res) => {
                this.valueattributes = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        GiftService.getAll()
            .then((res) => {
                this.gifts = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        onSubmitEditForm() {
            var proSame;
            var colorSame;
            var sizeSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.item.productId == element.productId & this.item.attributeId == element.attributeId & this.item.valueAttributeId == element.valueAttributeId) {
                    proSame = element.productId;
                    colorSame = element.attributeId;
                    sizeSame = element.valueAttributeId;
                    break;
                }
            }
            if (this.item.productId == proSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.item.attributeId == colorSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.item.valueAttributeId == sizeSame) {
                this.SameError = {
                    text: "",
                    status: true
                }
                this.SameSuccess = {
                    text: "",
                    status: false
                }
            } else {
                this.SameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.itemz.price.length == 0) {
                this.priceError = {
                    text: "price cannot be empty!",
                    status: true
                }
                this.priceSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.itemz.price.length > 0) {
                this.priceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.priceSuccess.status == true) {

                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    ItemService.update(this.itemz)
                        .then((res) => {
                            console.log(res);
                            this.itemz.productName = res.data.productName;
                            this.itemz.colorName = res.data.colorName;
                            this.itemz.sizeName = res.data.sizeName;
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.itemz);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại, đã có sản phẩm màu sắc kích thước này tồn tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
