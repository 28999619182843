<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới seri sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Seri sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productSeri" placeholder="" v-model="seriproduct.productSeri" :class="{ error: productSeriError.status, success: productSeriSuccess.status }">
                                <p class="text-error" v-if="productSeriError.status">{{ productSeriError.text }}</p>
                                <p class="success-text" v-if="productSeriSuccess.status">{{ productSeriSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control basic" name="" id="productId" v-model="seriproduct.productId" @change="updateProductName" :class="{ error: productNameError.status, success: productNameSuccess.status }">
                                    <option value="">Choose</option>
                                    <option v-for="item in proCate" :key="item.id" v-bind:value="item.id">
                                        {{ item.name}}</option>
                                </select>
                                <p class="text-error" v-if="productNameError.status">{{ productNameError.text }}</p>
                                <p class="success-text" v-if="productNameSuccess.status">{{ productNameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Thành phố</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control basic" name="" id="cityId" v-model="seriproduct.cityId" @change="changeCity($event)">
                                    <option value="" :selected="true">Choose</option>
                                    <option v-for="item in city" :key="item.id" v-bind:value="item.id" v-bind:id="item.id">
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <p>Tìm Đại lý</p>
                                <input type="text" class="form-control mb-2" v-model="query" placeholder="">
                                <select class="form-control basic" name="" id="locationId" v-model="seriproduct.locationId" @change="updateLocationName">
                                    <option value="" :selected="true">Choose</option>
                                    <option v-for="item in itemFilte" :key="item.id" v-bind:value="item.id">
                                        Mã đại lý: {{ item.code }}
                                        - Tên đại lý: <p v-html="item.description"></p>
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày xuất kho cho đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="date" class="form-control" id="dealerSaleDate" placeholder="" v-model="seriproduct.dealerSaleDate">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-body" v-for="(items, i) in item" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                            <p>Seri sản phẩm: {{ items.productSeri }}</p>
                            <p>Sản phẩm: {{ items.productName }}</p>
                            <p>Đại lý: {{ items.locationName }}</p>
                            <button type="button" class="btn btn-danger" @click="removeItem(i)">Xóa</button>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="button" class="btn btn-info mt-3" @click="addItem">Thêm tạm thời</button>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import SeriProductService from '@/services/SeriProductService';
import ProductService from "@/services/ProductService";
import LocationService from "@/services/LocationService";
import CityService from '@/services/CityService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            item: [],
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            city: null,
            proCate: null,
            query: "",
            location: null,
            ID: null,
            seriproduct: {
                id: null,
                productId: "",
                productName: "",
                cityId: "",
                locationId: "",
                locationName: "",
                productSeri: "",
                dealerSaleDate: "",
                status: true
            },
            productNameError: {
                text: "",
                status: false,
            },
            productNameSuccess: {
                text: "",
                status: false,
            },
            productSeriError: {
                text: "",
                status: false,
            },
            productSeriSuccess: {
                text: "",
                status: false,
            },

        }

    },

    mounted() {
        ProductService.getWaranty()
            .then((res) => {
                this.proCate = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            }),
            CityService.getAll()
            .then((res) => {
                this.city = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        SeriProductService.getAll().then((res) => {
            this.a = res.data
        })
    },
    computed: {
        itemFilte() {
            if (this.query) {
                return this.location.filter((loc) => {
                    return (
                        (loc.code).toLowerCase().indexOf(this.query.toLowerCase()) != -1 ||
                        (loc.name).toLowerCase().indexOf(this.query.toLowerCase()) != -1

                    )
                })
            } else {
                return this.location;
            }
        },
    },
    methods: {
        updateProductName() {
            const selectedProduct = this.proCate.find(
                (item) => item.id === this.seriproduct.productId
            );
            if (selectedProduct) {
                this.seriproduct.productName = selectedProduct.name;
            }
        },
        updateLocationName() {
            const selectedProduct = this.itemFilte.find(
                (item) => item.id === this.seriproduct.locationId
            );
            if (selectedProduct) {
                this.seriproduct.locationName = selectedProduct.name;
            }
        },
        addItem() {
            if (this.seriproduct.productSeri == '' || this.seriproduct.productId == '' || this.seriproduct.cityId == '' || this.seriproduct.locationId == '' || this.seriproduct.dealerSaleDate == '') {
                createToast({
                    title: 'Bạn không đc để trống',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var seriSame;
                for (let i = 0; i < this.item.length; i++) {
                    const element = this.item[i];
                    if (this.seriproduct.productSeri == element.productSeri) {
                        seriSame = element.productSeri;
                        break;
                    }
                }
                if (this.seriproduct.productSeri == seriSame) {
                    createToast({
                        title: 'Trùng lặp với danh sách seri tạm thời',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.item.push({
                        ...this.seriproduct
                    });
                }
            }

        },
        removeItem(i) {
            this.item.splice(i, 1);
        },
        onLocation(id) {
            LocationService.getCity(id).then((res) => {
                this.location = res.data
            })
        },
        changeCity(event) {
            const pro = event.target.options[event.target.options.selectedIndex].id;
            this.onLocation(pro);
        },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        onSubmitForm() {
            var seriSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.seriproduct.productSeri == element.productSeri) {
                    seriSame = element.productSeri;
                    break;
                }
            }
            if (this.seriproduct.productId.length == 0) {
                this.productNameError = {
                    text: "productName cannot be empty",
                    status: true
                };
                this.productNameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.seriproduct.productId.length > 0) {
                this.productNameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productNameError = {
                    text: "",
                    status: false
                }
            } else {
                this.productNameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.seriproduct.productSeri.length == 0) {
                this.productSeriError = {
                    text: "productSeri cannot be empty",
                    status: true
                };
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.seriproduct.productSeri == seriSame) {
                createToast({
                    title: 'Seri sản phẩm này đã có trong dữ liệu',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
                this.productSeriError = {
                    text: "Seri sản phẩm này đã có trong dữ liệu !",
                    status: true
                }
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.seriproduct.productSeri.length > 0) {
                this.productSeriSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productSeriError = {
                    text: "",
                    status: false
                }
            } else {
                this.productSeriSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.productSeriSuccess.status == true && this.productNameSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.item.length > 1) {
                        for (let i = 0; i < this.item.length; i++) {
                            const element = this.item[i];
                            SeriProductService.create(element)
                                .then((res) => {
                                    //Perform Success Action
                                    this.ID = res.data.id;
                                    this.seriproduct.id = this.ID;
                                    this.seriproduct.status = true;

                                    console.log(this.seriproduct);
                                    this.$emit("ShowData", this.seriproduct);
                                    setTimeout(() => {
                                        location.reload();
                                    }, 1000)
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                })
                                .finally(() => {
                                    //Perform action in always
                                });
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        }
                    } else {
                        SeriProductService.create(this.seriproduct)
                            .then((res) => {
                                //Perform Success Action
                                this.ID = res.data.id;
                                this.seriproduct.id = this.ID;
                                this.seriproduct.status = true;

                                console.log(this.seriproduct);
                                this.$emit("ShowData", this.seriproduct);
                                setTimeout(() => {
                                    location.reload();
                                }, 1000)
                            })
                            .catch((error) => {
                                // error.response.status Check status code
                                console.log(error);
                            })
                            .finally(() => {
                                //Perform action in always
                            });
                        createToast({
                            title: 'Thêm mới thành công',
                        }, {
                            position: 'top-right',
                            type: 'success',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    }

}
</script>
