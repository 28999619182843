<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa tài khoản Admin</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="accounts">
                <input type="hidden" v-model="accounts.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã tài khoản</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="accounts.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-danger" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="text-success" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="accounts.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-danger" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="text-success" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="accounts.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                                <p class="text-danger" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="text-success" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mật Khẩu</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <span class="text-danger"><i>Hãy nhập lại mật khẩu của bạn!</i></span>
                                <input type="password" class="form-control" id="password" placeholder="" v-model="accounts.password" :class="{ error: passwordError.status, success: passwordSuccess.status }">
                                <p class="text-danger" v-if="passwordError.status">{{ passwordError.text }}</p>
                                <p class="text-success" v-if="passwordSuccess.status">{{ passwordSuccess.text }}
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">

                        <div class="form-group row mb-4">
                            <label for="role" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Quyền hạn</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control  basic" v-model="accounts.role" id="role">
                                    <option value=""> Choose Role:</option>
                                    <option v-bind:value="0" :selected="accounts.role == 0">Admin Xem</option>
                                    <option v-bind:value="1" :selected="accounts.role == 1">Admin Chỉnh sửa</option>
                                    <option v-bind:value="2" :selected="accounts.role == 2">Admin Quản lý</option>
                                </select>

                            </div>
                        </div>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="accounts.status" :value="true" :checked="accounts.status === true" id="status" style="width: 16px;height: 16px;" />
                                        <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="accounts.status" :value="false" :checked="accounts.status === false" id="status" style="width: 16px;height: 16px;" />
                                        <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import AccountService from '@/services/AccountAdminService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['account'],

    data() {

        return {
            accounts: {
                id: this.account.id,
                code: this.account.code,
                email: this.account.email,
                name: this.account.name,
                password: '',
                role: this.account.role,
                status: this.account.status
            },
            url: null,
            ishowImage: false,
            category: null,
            message: "",
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            passwordError: {
                text: "",
                status: false,
            },
            passwordSuccess: {
                text: "",
                status: false,
            },
            roleError: {
                text: "",
                status: false,
            },
            roleSuccess: {
                text: "",
                status: false,
            }

        }

    },
    mounted() {

    },
    methods: {

        onSubmitEditForm() {

            if (this.accounts.code.length == 0) {
                this.codeError = {
                    text: "Mã tài khoản không được để trống",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.code.length < 4) {
                this.codeError = {
                    text: "Mã tài khoản phải trên 4 kí tự",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.accounts.code.length >= 4) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeError = {
                    text: "",
                    status: false
                }
            }

            if (this.accounts.name.length == 0) {
                this.nameError = {
                    text: "Tên không được để trống!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.accounts.name.length <= 3 || this.accounts.name.length > 50) {
                this.nameError = {
                    text: "Tên phải từ 3 đến 50 kí tự",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.name.length >= 3 || this.accounts.name.length < 50) {
                this.nameSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }

            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.accounts.email.length == 0) {
                this.emailError.text = "Email không được để trống!",
                    this.emailError.status = true
                this.emailSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex.test(this.accounts.email)) {
                this.emailError.text = "Email sai định dạng !",
                    this.emailError.status = true
                this.emailSuccess = {
                    text: "",
                    status: false
                }
            } else if (regex.test(this.accounts.email)) {
                this.emailSuccess.text = "Thành công!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            if (this.accounts.password.length == 0) {
                this.passwordError = {
                    text: "Mật khẩu không được để trống",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.accounts.password.length < 6) {
                this.passwordError = {
                    text: "Mật khẩu phải trên 6 kí tự",
                    status: true
                }
                this.passwordSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.accounts.password.length >= 6) {
                this.passwordSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.passwordError = {
                    text: "",
                    status: false
                }
            } else {
                this.passwordError = {
                    text: "",
                    status: false
                }
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.emailSuccess.status == true && this.passwordSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 2) {
                    AccountService.update(this.accounts)
                        .then((res) => {
                            console.log(res);
                            this.$emit("ShowEditData", this.accounts);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Chỉnh sửa thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        }
    }

}
</script>
